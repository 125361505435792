<template>
  <div style="background-color: #f8f8fd;min-height: 100vh;">
    <back-nav :icon="'img/backb.png'" :textColor="'#111111'" :backColor="'#F7F8FC'" />
    <div class="order-title display_flex align-items_center justify-content_flex-justify">
      <div class="order-nav" :class="{ 'order_cur': navCur == index }" v-for="(item, index) in nav" :key="index"
        @click="navSelect(index)">{{ item.name }}</div>
    </div>
    <div class="order_list">
      <van-list :finished="finished" finished-text="没有更多了" v-model="loading" @load="fetchData">
        <div class="cash_item" v-for="(item, index) in orderList" :key="index" @click="goDetail(item, 'orderCenterDetail')">
          <div class="cash_time display_flex justify-content_flex-justify align-items_center">
            <p>{{ status(item.state) }}</p>
            <span style="color:#ffffff">{{ item.create_time }}</span>
          </div>
          <div class="cash_detail display_flex align-items_center">
            <img :src="item.head_portrait" alt="">
            <div class="cash_text margin-left flex_1">
              <p>{{ item.nickname }}</p>
              <div class="display_flex justify-content_flex-justify align-items_center margin-top-sm">
                <div class="display_flex align-items_center">
                  <img :src="item.game_logo" alt="">
                  <span class="margin-lr-xs">{{ item.game_name }}</span>
                  <p class="margin-right-xs">{{ item.price }}</p>
                  <img src="img/bi.png" alt="">
                  <span>/局</span>
                </div>
                <p class="margin-left-xs">x{{ item.num }}</p>
              </div>

            </div>
          </div>
          <div class="cash_money display_flex justify-content_flex-justify align-items_center">
            <div class="display_flex align-items_center">
              <span>已支付</span>
              <p class="margin-lr-xs">{{ item.total_price }}</p>
              <img src="img/bi.png" alt="">
              <div style="width:10px"></div>
              <p class="margin-lr-xs" v-if="(item.ticket_pic != null)">1</p>
              <img :src="item.ticket_pic" alt="" v-if="item.ticket_pic != null">
            </div>

            <div class="display_flex" v-if="(item.state == 12 || item.state == 13) && navCur == 0">
              <!-- <div class="order_ping">评价</div> -->
              <div class="order_again margin-left-sm" style="background-image: url('img/order_again.png');"
                @click.stop="goDetail(item, 'orderAgain')"></div>
            </div>
          </div>
        </div>
      </van-list>
      <div class="noData margin-top-xl" v-if="orderList.length == 0">
        <img src="img/noData.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import BackNav from '@/components/backNav.vue';
import { orderList } from "@/api/api";
export default {
  name: 'orderCenter',
  components: {
    BackNav
  },
  data() {
    return {
      navCur: 0,
      nav: [
        {
          name: '我的下单',
          type: 1
        },
        {
          name: '我的接单',
          type: 2
        }
      ],
      page: 1,
      row: 10,
      finished: false,
      loading:false,
      orderList: []
    };
  },
  computed: {
    // 订单状态1待确认  2已取消  3已拒绝  4进行中 5已退款 6退款中 7已完成
    status() {
      return function (e) {
        if (e == 1) {
          return '待确认'
        } else if (e == 2) {
          return '已取消'
        } else if (e == 3) {
          return '已拒绝'
        } else if (e == 4) {
          return '已拒绝'
        } else if (e == 5) {
          return '进行中'
        } else if (e == 6) {
          return '进行中'
        } else if (e == 7) {
          return '进行中'
        } else if (e == 8) {
          return '进行中'
        } else if (e == 9) {
          return '已退款'
        } else if (e == 10) {
          return '已退款'
        } else if (e == 11) {
          return '退款中'
        } else if (e == 12) {
          return '已完成'
        } else if (e == 13) {
          return '已完成'
        }
      }
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    // this.fetchData()
  },
  // mounted() {

  //   window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动
  // },
  // destroyed() {
  //   window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  // },
  methods: {
    fetchData() {
      let type = this.navCur + 1
      let param = {
        page: this.page,
        rows: this.row,
        order_type: type
      }
      orderList(param).then(response => {
        this.orderList = this.orderList.concat(response.data);
        this.loading = false;
        if (response.data.length < 10) {
          this.finished = true;
        }
        this.page = this.page + 1
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    navSelect(e) {
      this.navCur = e
      this.orderList = [];
      this.page = 1
      this.loading = true;
      this.finished = false;
      this.fetchData();
    },
    goDetail(item, key) {
      let params = JSON.stringify(item)
      this.$bridge.callhandler(key, params, (data) => {
        // 处理返回数据
      })
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    // Scrollbottom() {
    //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //   let clientHeight = document.documentElement.clientHeight;
    //   let scrollHeight = document.documentElement.scrollHeight;
    //   if (scrollTop + clientHeight >= scrollHeight) {

    //     this.fetchData()
    //     // this.pageNo++
    //     // console.log(this.pageNo);
    //     // this.fetchData()

    //   }
    // },

  },
  watch: {
    '$route': "fetchData"
  }
};
</script>
